<template>
  <BaseModal name="sales-filters" size="lg" title="Filtros" @shown="openModal">
    <b-row>
      <b-col cols="12">
        <!-- Header -->
        <div class="top-filter">
          <div>
            <p>{{ $t('seller.sales.text_2627') }}</p>
          </div>
          <BaseButton
            variant="info3"
            class="link-documentacao"
            id="limpar"
            @click="limparLocal"
            :disabled="!contemFilterLocal"
            >{{ $t('seller.sales.text_2629') }}</BaseButton
          >
        </div>
        <b-form novalidate>
          <!-- Products -->
          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2630')">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.sales.text_2631')"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_product_debounce"
                >
                  <span slot="noOptions">{{
                    $t('seller.sales.text_2632')
                  }}</span>

                  <span slot="noResult">{{
                    $t('seller.sales.text_2633')
                  }}</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Affiliated -->
          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2634')">
                <multiselect
                  v-model="filter.affiliates"
                  :placeholder="$t('seller.sales.text_2635')"
                  label="name"
                  track-by="id"
                  :options="affiliates"
                  :multiple="true"
                  :taggable="false"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  ><span slot="noResult">{{
                    $t('seller.sales.text_2636')
                  }}</span></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Contracts -->
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('seller.sales.text_2637')"
                label-for="contracts"
              >
                <multiselect
                  v-model="filter.contracts"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.sales.text_2638')"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="contracts"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading_contract"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_contract_debounce"
                >
                  <span slot="noOptions">{{
                    $t('seller.sales.text_2639')
                  }}</span>

                  <span slot="noResult">{{
                    $t('seller.sales.text_2641')
                  }}</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Methods -->
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.sales.text_2642')"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('metodos')"
                  class="btn-todos"
                  >{{ $t('seller.sales.text_2643') }}</a
                >
                <multiselect
                  v-model="filter.method"
                  :placeholder="$t('seller.sales.text_2643')"
                  label="name"
                  track-by="id"
                  :options="method_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  ><span slot="noResult">{{
                    $t('seller.sales.text_2644')
                  }}</span></multiselect
                >
              </b-form-group>
            </b-col>
            <!-- Status -->
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.sales.text_2645')"
                label-for="status"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('status')"
                  class="btn-todos"
                  >{{ $t('seller.sales.text_2646') }}</a
                >
                <multiselect
                  v-model="filter.status"
                  :placeholder="$t('seller.sales.text_2646')"
                  label="name"
                  track-by="id"
                  :options="status_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  ><span slot="noResult">{{
                    $t('seller.sales.text_2647')
                  }}</span></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Origin of sale -->
          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2655')">
                <multiselect
                  v-model="filter.salesOrigin"
                  :placeholder="$t('seller.sales.text_2656')"
                  label="name"
                  track-by="id"
                  :options="salesOrigin"
                  :multiple="false"
                  class=""
                  selectLabel=""
                  ><span slot="noResult">{{
                    $t('seller.sales.text_2636')
                  }}</span></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Meta Tag Name -->
            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('seller.sales.text_2648')"
                label-for="meta_key"
              >
                <b-form-input
                  id="meta_key"
                  v-model="filter.meta_key"
                  type="text"
                  placeholder="utm_source"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- Meta Tag Value -->
            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('seller.sales.text_2649')"
                label-for="meta_value"
              >
                <b-form-input
                  id="meta_value"
                  v-model="filter.meta_value"
                  type="text"
                  placeholder="google"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('seller.sales.text_2650') }}
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit">
        {{ $t('seller.sales.text_2651') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from 'vue-multiselect'
import _ from 'lodash'

import ProductService from '@/services/resources/ProductService'
const serviceProduct = ProductService.build()

import ContractService from '@/services/resources/ContractService'
const serviceContract = ContractService.build()

import SaleService from '@/services/resources/SaleService'
import { paymentMethodsList } from '../../../../config/paymentMethods'
const serviceSale = SaleService.build()

export default {
  props: {
    value: {
      type: Number
    }
    // pre_filter: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
  },
  components: {
    Multiselect
  },
  data() {
    return {
      pre_filter: {},
      contemFilterLocal: false,
      search: '',
      loading: false,
      loading_contract: false,
      affiliates: [],
      salesOrigin: [
        { name: 'Todas as vendas', id: '0' },
        { name: 'Vendas nacionais', id: '1' },
        { name: 'Vendas internacionais', id: '2' }
      ],

      filter: {
        affiliates: [],
        salesOrigin: [{ name: 'Todas as vendas', id: '0' }],

        status: [
          {
            id: 'paid',
            name: 'Pago'
          },
          {
            id: 'waiting_payment',
            name: 'Aguardando pagamento'
          }
        ],
        method: paymentMethodsList,
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: []
      },
      products: [],
      contracts: [],
      types_list: [
        { value: 'created_at', text: 'Criação' },
        { value: 'paid_at', text: 'Pagamento' }
      ],

      status_list: [
        {
          id: 'processing',
          name: 'Processando'
        },
        {
          id: 'authorized',
          name: 'Transação autorizada'
        },
        {
          id: 'paid',
          name: 'Pago'
        },

        {
          id: 'refunded',
          name: 'Reembolsado'
        },
        {
          id: 'waiting_payment',
          name: 'Aguardando pagamento'
        },

        {
          id: 'refund_pending',
          name: 'Reembolso pendente'
        },
        {
          id: 'refused',
          name: 'Recusado'
        },

        {
          id: 'chargedback',
          name: 'ChargeBack'
        },
        {
          id: 'delayed',
          name: 'Em processamento'
        }
      ],

      method_list: paymentMethodsList
    }
  },

  methods: {
    selecionarTodos(type) {
      switch (type) {
        case 'metodos': {
          this.filter.method = paymentMethodsList
          break
        }
        case 'status': {
          this.filter.status = [
            {
              id: 'processing',
              name: 'Processando'
            },
            {
              id: 'authorized',
              name: 'Transação autorizada'
            },
            {
              id: 'paid',
              name: 'Pago'
            },

            {
              id: 'refunded',
              name: 'Reembolsado'
            },
            {
              id: 'waiting_payment',
              name: 'Aguardando pagamento'
            },

            {
              id: 'refund_pending',
              name: 'Reembolso pendente'
            },
            {
              id: 'refused',
              name: 'Recusado'
            },

            {
              id: 'chargedback',
              name: 'ChargeBack'
            },
            {
              id: 'delayed',
              name: 'Em processamento'
            }
          ]
          break
        }
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading = true
      this.search = query
      this.debounce_product()
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search)
    }, 500),

    // contrato
    aux_contract_debounce(query) {
      this.loading_contract = true
      this.search = query
      this.debounce_contract()
    },
    debounce_contract: _.debounce(function () {
      this.fetchContract(this.search)
    }, 500),

    onSubmit() {
      window.localStorage.sales_filters_aux = JSON.stringify({
        status: this.filter.status,
        method: this.filter.method,
        meta_key: this.filter.meta_key,
        meta_value: this.filter.meta_value,
        products: this.filter.products,
        contracts: this.filter.contracts,
        affiliates: this.filter.affiliates,
        salesOrigin: this.filter.salesOrigin ?? [
          { name: 'Todas as vendas', id: '0' }
        ]
      })
      let data = {
        status: this.filter.status.map(item => item.id),
        method: this.filter.method.map(item => item.id),
        meta_key: this.filter.meta_key,
        meta_value: this.filter.meta_value,
        salesOrigin: this.filter.salesOrigin?.id ?? '0'
      }
      if (this.filter.affiliates.length) {
        data.affiliates = this.filter.affiliates.map(item => item.id)
      }
      if (this.filter.products.length) {
        data.products = this.filter.products.map(item => item.id)
      }

      if (this.filter.contracts.length) {
        data.contracts = this.filter.contracts.map(item => item.id)
      }

      this.$emit('filter', data)
      this.$bvModal.hide('sales-filters')
    },
    fetchProducts(search = null) {
      let data = {
        list: true,
        with_participants: true
      }

      if (search) data.search = search

      this.products = []

      serviceProduct
        .search(data)
        .then(response => {
          for (let element of response) {          
            if (this.products.length > 0 && this.products.find((item) => item.id === element.id)) {
              continue;
            }
            this.products.push({ id: element.id, name: element.name })
          }
        })
        .catch(err => {
          console.log(err)
          this.products = []
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchContract(search = null) {
      let data = {
        // list: true,
        id: search
      }

      // if (search) data.search = search;

      this.contracts = []

      serviceContract
        // .search(data)
        .read(data)
        .then(response => {
          if (response.data) {
            response.data.data.forEach(element => {
              this.contracts.push({
                id: element.id,
                name: `${element.contract.live.product.name} | ${element.client.name} `
              })
            })
          } else {
            this.contracts.push({
              id: response.id,
              name: `${response.contract.live.product.name} | ${response.client.name} `
            })
          }
        })
        .catch(err => {
          this.contracts = []
          console.log(err)
        })
        .finally(() => {
          this.loading_contract = false
        })
    },
    fetchAffiliates() {
      let data = {
        id: '/affiliate'
      }

      this.affiliates = []

      serviceSale
        .read(data)
        .then(response => {
          response.forEach(element => {
            this.affiliates.push({
              id: element.id,
              name: `${element.name}  - ${element.email}`
            })
          })
        })
        .catch(err => {
          console.log(err)
          this.affiliates = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    limparLocal() {
      window.localStorage.removeItem('sales_filters_aux')
      window.localStorage.removeItem('sales_filters')
      this.filter = {
        status: [
          {
            id: 'paid',
            name: 'Pago'
          },
          {
            id: 'waiting_payment',
            name: 'Aguardando pagamento'
          }
        ],
        method: paymentMethodsList,
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
        affiliates: [],
        salesOrigin: [{ name: 'Todas as vendas', id: '0' }]
      }
      this.products = []
      this.contracts = []
      this.affiliates = []
      this.verificaLocal()
    },
    verificaLocal() {
      if (window.localStorage.sales_filters_aux) {
        this.contemFilterLocal = true
      } else {
        this.contemFilterLocal = false
      }
    },

    openModal() {
      this.verificaLocal()
      if (window.localStorage.sales_filters_aux) {
        this.pre_filter = JSON.parse(window.localStorage.sales_filters_aux)
        this.filter = this.pre_filter
      }
    }
  },
  mounted() {
    this.fetchAffiliates()
  },
  created() {
    // this.fetchProducts();
  }
}
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
